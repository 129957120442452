<template>
	<div>
		<div class="ft20 cl-main ftw600">次卡订单</div>
		
		<div class="mt20">
			<div class="form-search-box ">
				<a-form layout="inline">
					<a-form-item label="订单编号">
						<a-input v-model="search.member_once_card_id" placeholder="请输入订单编号"></a-input>
					</a-form-item>
					
					<a-form-item label="会员手机号">
						<a-input v-model="search.mobile" placeholder="请输入会员手机号"></a-input>
					</a-form-item>
					<a-form-item  v-if="shop_id==0" label="站点名称">
						<a-input v-model="search.shop_name" placeholder="请输入站点名称"></a-input>
					</a-form-item>
			
					<a-form-item>
						<a-button @click="searchAct" type="primary">查询</a-button>
						<a-button @click="cancelAct" class="ml10">取消</a-button>
					</a-form-item>
				</a-form>
			</div>
		</div>
		
		<div class="mt20">
			<div class="pd30 bg-w " style="min-height: 800px;">
					<div class="mt20 ">
						<a-radio-group v-model="search.status" button-style="solid" @change="changeStatus">
							<a-radio-button v-if="shop_id==0" :value="0">
								全部
							</a-radio-button>
							<a-radio-button :value="1">
							  待消费
							</a-radio-button>
							<a-radio-button :value="2">
							  已完成
							</a-radio-button>
							<a-radio-button :value="3">
							  已过期
							</a-radio-button>
						</a-radio-group>
					</div>
					
					<div class=" mt20">
						<div class="wxb-table-gray">
							<a-table rowKey="member_once_card_id" :columns="columns" :pagination="pagination" @change="handleTableChange"
								:data-source="datas" :loading="loading">
								
								<div class="right" slot="need_pay" slot-scope="need_pay,record">
									¥{{need_pay}}
								</div>
								<div class="flex alcenter left" slot="shop" slot-scope="shop,index">
									<a-tag v-if="shop.name" color="purple">
										{{ shop.name }}
									</a-tag>
									<a-tag v-else color="green">平台</a-tag>
								</div>
								<div  slot="member" slot-scope="member,record">
									<div class="flex alcenter left" v-if="member != null">
										<img v-if="member.face != null" :src="member.face"  class="member-index-face" />
										<img v-else src="../../assets/image/woyaoyou_head_defult@2x.png"  class="member-index-face" />
										<div class="ml5" style="text-align: left;">
											<div>{{record.member ? record.member.nick_name :'-'}}</div>
											<div>{{record.member ? record.member.mobile :''}}</div>
										</div>
									</div>
									<div v-else>-</div>
								</div>
							
								<template slot="action" slot-scope="record,index">
									<div class="flex center">
										<a-dropdown placement="bottomRight">
											<span class="more-act">
												<i class="iconfont iconmore_gray"></i>
											</span>
											<a-menu slot="overlay">
												<a-menu-item>
													<a class="menu-act" href="javascript:;" @click="see(record)">
														<i class="iconfont ft14 iconsee"></i>
														<span class="ml10">查看</span>
													</a>
												</a-menu-item>
											</a-menu>
										</a-dropdown>
									</div>
								</template>
							</a-table>
						</div>
					</div>
				</div>
			
		</div>
	</div>
</template>

<script>
	import {listMixin} from '../../common/mixin/list.js';
	export default{
		mixins: [listMixin],
		data(){
			return{
				loading: false,
				shop_id:1,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				search:{
					member_once_card_id:'',
					mobile:'',
					status:1,
                    shop_name:'',
				},
				columns: [
					// {title: '订单编号',dataIndex: 'member_once_card_id',align: 'center'},
					// {title: '次卡',dataIndex: 'name',align: 'center',ellipsis: true},
					// {title: '实付',dataIndex: 'need_pay',align: 'center',scopedSlots: {customRender: 'need_pay'}},
					// {title: '用户',dataIndex: 'member',align: 'center',scopedSlots: {customRender: 'member'}},
					// {title: '手机号',key: 'mobile',align: 'center',scopedSlots: {customRender: 'mobile'}},
					// {title: '下单时间',dataIndex: 'add_time_format',align: 'center',ellipsis: true},
					// {title: '有效期至',dataIndex: 'valid_end_time',align: 'center',ellipsis: true},
					// {title: '订单状态',dataIndex: 'status_means',align: 'center',ellipsis: true},
					// {title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}}
				],
				datas: [],
			}
		},
		methods:{
			getLists() {
				if (this.loading == true) return;
				this.loading = true;
				this.$http.api('admin/getOncecardOrderList', {
					limit: this.pagination.pageSize,
					page: this.pagination.current,
					member_once_card_id: this.search.member_once_card_id,
					status: this.search.status,
					mobile: this.search.mobile,
                    shop_name: this.search.shop_name,
				}).then(res => {
					this.pagination.total = res.total;
					this.datas = res.list;
					this.shop_id=res.shop_id;
					this.columns=res.columns;
					this.loading = false;
				}).catch(res => {
					this.loading = false;
				})
			},
			see(record){
				this.$router.push('/project/orderOncecardDetail?id='+record.member_once_card_id);
			},
			
			changeStatus(){
				this.pagination.current=1;
				this.getLists();
			},
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
	.member-index-face{
		width: 44px;
		height: 44px;
		border-radius: 44px;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
		border: 2px solid #FFFFFF;
	}
	
	.once-card-flash-sale-tag{
		width: 40px;
		height: 20px;
		border-radius: 3px;
	}
</style>
